<template>
  <div class="about">
    <h1>Willkommen in der wunderbaren Welt der Mathematik</h1>
    <h2>Meine erste kleine Taschenrechner-App</h2>
    <h3>&copy; 2022<br />by Sascha Bausch</h3>
  </div>
</template>

<style scoped>
h2 {
  font-family: cursive;
}
h3 {
  margin-top: 150px;
  font-family: cursive;
}
</style>
